module.exports = {
  COUNTRY_CODE: 'US',
  KM_TO_MILES: '0.621504',
  PERSONAL_INFO: 'pi',
  LOGIN_SECURITY: 'ls',
  MARKETING_CONSENT: 'mc',
  MARKETING_CONSENTS_EDITOR: 'mc-editor',
  VEHICLE_INFO: 'vi',
  MANAGE_ALERTS: 'ma',
  THIRD_PARTY: 'tp',
  US_LOCALE: 'en-US',
  CARNET_SUBSCRIPTIONS: 'cn-subs',
  ADDITIONAL_DRIVERS: 'ad',
  WIFI_SETTINGS: 'wifi_settings',
  WIFI_HOTSPOT_SETTINGS: 'wifi_hotspot_settings',
  DEFAULT_MSGCENTER_PAGING_ADVANCE: 10,
  TOOLTIP_DISSOLVE_DAMPING_MS: 2000,
  SERVICES_EDITOR: 'services_editor',
  VEHICLE_NICKNAME_EDITOR: 'veh_nickname_editor',
  FIND_DEALER: 'find_a_dealer',
  PRIMARY_DRIVER_ROLE: 'Primary Driver',
  SECONDARY_DRIVER_ROLE: 'Secondary Driver',
  UNKNOWN_DRIVER_ROLE: 'Driver Role Unknown',
  DRIVE_VIEW: 'DriveView',
  TSP_ATC: 'ATC',
  TSP_VZT: 'VZT',
  TSP_WCT: 'WCT',
  FUEL_ECONOMY_KM_TO_MILES: '235.215',
  READ: 'Read',
  WRITE: 'Write',
  DELETE: 'Delete',
  ENGINE_TYPE_BEV: 'BEV',
  ENGINE_TYPE_ICE: 'ICE',
  EVENTTYPE_INSPECTION: 'INSPECTION',
  EVENTTYPE_OIL: 'OIL',
  INDEX_INCREMENT_VALUE: 14,
  AVG_FUEL_DIV_VALUE: 100,
  TIPCODE_HARD_BREAKING: 'HARD_BRAKE',
  TIPCODE_ACCELERATION: 'ACCELERATION',
  OVERALL_PRIORITY_CODE_A: 'A',
  OVERALL_PRIORITY_CODE_B: 'B',
  OVERALL_PRIORITY_CODE_C: 'C',
  OVERALL_PRIORITY_CODE_D: 'D',
  PRIORITY_DESC_DONOT_DRIVE: 'DONOT_DRIVE',
  PRIORITY_DESC_NEED_ATTENTION: 'NEED_ATTENTION',
  PRIORITY_DESC_SERVICE_DUE: 'SERVICE_DUE',
  PRIORITY_DESC_SERVICE_OVERDUE: 'SERVICE_OVERDUE',
  RNR_STATUS_AVAILABLE: 'AVAILABLE',
  CURRENT_PLAN_STATUS_ACTIVE: 'ACTIVE',
  CURRENT_PLAN_TITLE: 'HOTSPOT - UNLIMITED WI-FI',
  NATIVE_NAME_FRENCH_LANGUAGE: 'Français',
  TOS_STATUS_ACCEPT: 'Accept',
  VW_CARNET_URL: 'https://vw.com/carnet',
  MNO_PROVIDER_TMO: 'TMO',
  MNO_PROVIDER_VERIZON: 'Verizon',
  MNO_PROVIDER_ROGERS: 'ROGERS',
  PLAN_TYPE_DATA: 'DATA',
  VWCOM_CARNETAPP_FAQ_URL:
    'https://www.vw.com/en/help-center.html/__app/categories/owner-services-benefits/car-net.app',
  VWCOM_CARNET_STATIC_CONTENT_URL:
    'https://www.vw.com/en/owners-and-services/connectivity-and-apps/connected-services.html',
  VWCOM_CARNET_TOS_URL: 'https://www.vw.com/en/terms.html#carnet-terms-of-service',
  TS_R: 'TS:*:R'
};
