import React, { Component } from 'react';
import Loader from '../common/loader';
import Snapshot from './snapShot';
import InsuranceMessages from './insuranceMessages';
import ConsolidateTripSummary from './consolidateTripSummary';
import TripsHistory from './tripsHistory';
import './driveView.scss';
import './mockData.json';
import axios from 'axios';
import { getLocaleTranslator, findErrorMessage } from '../../../util/i18n/i18nService';
import {
  getDayTrackingDate,
  getWeekTrackingDate,
  getMonthTrackingDate,
  getTodaysDate,
  getWeeklyStartDate,
  getEpochDayStartDate,
  getEpochDayEndDate,
  getEpochStartWeek,
  getEpochEndWeek,
  getEpochMonthStart,
  getEpochMonthEnd
} from '../common/commonDateUtil';

import GeicoMsgAvailable from './geicoMsgAvailable';
import { combinedDriveViewFasFlags } from '../providers/FeatureAssessmentProvider';
import { fetchDriveViewDetails } from '../providers/DriveviewProvider';

const DAY = 'daily';
const WEEK = 'weekly';
const MONTH = 'monthly';

class DriveViewHome extends Component {
  constructor (props) {
    super(props);
    this.state = {
      driveViewInfo: {},
      insuranceMessagesInfo: {},
      offers: {},
      dataLoading: false,
      daySelected: true,
      weekSelected: false,
      monthSelected: false,
      previousSelected: false,
      nextSelected: false,
      trackDate: null,
      type: 'daily'
    };
    this.handleViewType = this.handleViewType.bind(this);
    this.handlePrevNext = this.handlePrevNext.bind(this);
    this.setViewTypeState = this.setViewTypeState.bind(this);
    this.setHandlePrevNextState = this.setHandlePrevNextState.bind(this);
    this.translator = getLocaleTranslator();
  }

  componentDidMount () {
    this.getDriveViewDetails(
      this.state.type,
      getEpochDayStartDate(getTodaysDate()),
      getEpochDayEndDate(getTodaysDate()),
      this.state.trackDate
    );
    this.getFasBasedFeatures();
  }

  getFasBasedFeatures = async () => {
    try {
      let { geicoMsgIsAvailable, insuranceOffersAvailable } = await combinedDriveViewFasFlags();
      this.setState({ geicoMsgIsAvailable, insuranceOffersAvailable });
    } catch (err) {
      console.log('unable to retrieve FAS rules for drive view', err);
    }
  };

  handleViewType = type => {
    let viewType = {};
    if (type === DAY) {
      this.getDriveViewDetails(
        DAY,
        getEpochDayStartDate(getTodaysDate()),
        getEpochDayEndDate(getTodaysDate()),
        getTodaysDate()
      );
      viewType = {
        daySelected: true,
        type: type
      };
      this.setViewTypeState(viewType);
    } else if (type === WEEK) {
      this.getDriveViewDetails(
        WEEK,
        getEpochStartWeek(getWeeklyStartDate()),
        getEpochEndWeek(getWeeklyStartDate()),
        getWeeklyStartDate()
      );
      viewType = {
        weekSelected: true,
        type: type
      };
      this.setViewTypeState(viewType);
    } else {
      this.getDriveViewDetails(
        MONTH,
        getEpochMonthStart(getTodaysDate()),
        getEpochMonthEnd(getTodaysDate(), getTodaysDate())
      );
      viewType = {
        monthSelected: true,
        type: type
      };
      this.setViewTypeState(viewType);
    }
  };

  setViewTypeState = viewType => {
    this.setState({
      daySelected: viewType.daySelected ? true : false,
      weekSelected: viewType.weekSelected ? true : false,
      monthSelected: viewType.monthSelected ? true : false,
      previousSelected: viewType.previousSelected ? true : false,
      nextSelected: viewType.nextSelected ? true : false,
      trackDate: viewType.type === WEEK ? getWeeklyStartDate() : getTodaysDate(),
      type: viewType.type
    });
  };

  /* TO the best I have written this logic and not sure whether we can still decompose this into small chunks.
      User by default renders with daily wise data and has provision to switch to weekly or monthly wise data. 
      In either of day, weekly or monthly wise user also has given provision to navigate to previous or next daily, weekly or monthlt to view data.
      When user is doing these actions we need to invoke the API and also change the corresponiding date range values by updating and maintianing the sate.
  */
  handlePrevNext = (daySelected, weekSelected, monthSelected, previousSelected, nextSelected, trackDate) => {
    if (monthSelected) {
      if (previousSelected) {
        this.getDriveViewDetails(
          MONTH,
          getEpochMonthStart(getMonthTrackingDate(monthSelected, previousSelected, nextSelected, trackDate)),
          getEpochMonthEnd(getMonthTrackingDate(monthSelected, previousSelected, nextSelected, trackDate)),
          getMonthTrackingDate(monthSelected, previousSelected, nextSelected, trackDate)
        );
        this.setHandlePrevNextState(
          daySelected,
          weekSelected,
          monthSelected,
          previousSelected,
          nextSelected,
          getMonthTrackingDate(monthSelected, previousSelected, nextSelected, trackDate)
        );
      } else {
        this.getDriveViewDetails(
          MONTH,
          getEpochMonthStart(getMonthTrackingDate(monthSelected, previousSelected, nextSelected, trackDate)),
          getEpochMonthEnd(getMonthTrackingDate(monthSelected, previousSelected, nextSelected, trackDate)),
          getMonthTrackingDate(monthSelected, previousSelected, nextSelected, trackDate)
        );
        this.setHandlePrevNextState(
          daySelected,
          weekSelected,
          monthSelected,
          previousSelected,
          nextSelected,
          getMonthTrackingDate(monthSelected, previousSelected, nextSelected, trackDate)
        );
      }
    } else if (daySelected) {
      this.getDriveViewDetails(
        DAY,
        getEpochDayStartDate(getDayTrackingDate(daySelected, previousSelected, nextSelected, trackDate)),
        getEpochDayEndDate(getDayTrackingDate(daySelected, previousSelected, nextSelected, trackDate)),
        getDayTrackingDate(daySelected, previousSelected, nextSelected, trackDate)
      );
      if (previousSelected) {
        this.setHandlePrevNextState(
          daySelected,
          weekSelected,
          monthSelected,
          previousSelected,
          nextSelected,
          getDayTrackingDate(daySelected, previousSelected, nextSelected, trackDate)
        );
      } else {
        this.getDriveViewDetails(
          DAY,
          getEpochDayStartDate(getDayTrackingDate(daySelected, previousSelected, nextSelected, trackDate)),
          getEpochDayEndDate(getDayTrackingDate(daySelected, previousSelected, nextSelected, trackDate)),
          getDayTrackingDate(daySelected, previousSelected, nextSelected, trackDate)
        );
        this.setHandlePrevNextState(
          daySelected,
          weekSelected,
          monthSelected,
          previousSelected,
          nextSelected,
          getDayTrackingDate(daySelected, previousSelected, nextSelected, trackDate)
        );
      }
    } else if (weekSelected) {
      if (previousSelected) {
        this.getDriveViewDetails(
          WEEK,
          getEpochStartWeek(getWeekTrackingDate(weekSelected, previousSelected, nextSelected, trackDate)),
          getEpochEndWeek(getWeekTrackingDate(weekSelected, previousSelected, nextSelected, trackDate)),
          getWeekTrackingDate(weekSelected, previousSelected, nextSelected, trackDate)
        );
        this.setHandlePrevNextState(
          daySelected,
          weekSelected,
          monthSelected,
          previousSelected,
          nextSelected,
          getWeekTrackingDate(weekSelected, previousSelected, nextSelected, trackDate)
        );
      } else {
        this.getDriveViewDetails(
          WEEK,
          getEpochStartWeek(getWeekTrackingDate(weekSelected, previousSelected, nextSelected, trackDate)),
          getEpochEndWeek(getWeekTrackingDate(weekSelected, previousSelected, nextSelected, trackDate)),
          getWeekTrackingDate(weekSelected, previousSelected, nextSelected, trackDate)
        );
        this.setHandlePrevNextState(
          daySelected,
          weekSelected,
          monthSelected,
          previousSelected,
          nextSelected,
          getWeekTrackingDate(weekSelected, previousSelected, nextSelected, trackDate)
        );
      }
    }
  };

  setHandlePrevNextState = (daySelected, weekSelected, monthSelected, previousSelected, nextSelected, trackDate) => {
    this.setState({
      daySelected: daySelected,
      weekSelected: weekSelected,
      monthSelected: monthSelected,
      previousSelected: previousSelected,
      nextSelected: nextSelected,
      trackDate: trackDate
    });
  };

  getDriveViewDetails = async (type, startDate, endDate, trackDate) => {
    try {
      let response = await fetchDriveViewDetails(type, startDate, endDate);
      if (response?.data) {
        this.setState({
          driveViewInfo: response?.data,
          dataLoading: true,
          trackDate: trackDate === null ? getTodaysDate() : trackDate
        });
      }
    } catch (error) {
      /* Error scenario we have render drive view screen and set snapshot scrores as 0 */
      this.setState(
        {
          driveViewInfo: {
            snapShot: {
              tripWise: {
                score: 0,
                idleTimePercent: 0,
                hardBrakeCount: 0
              }
            }
          },
          insuranceMessagesInfo: {},
          dataLoading: true,
          trackDate: trackDate === null ? getTodaysDate() : trackDate
        },
        () => {
          if (this.props.setUbiPausedIfNotSet) {
            this.props.setUbiPausedIfNotSet();
          }
        }
      );
      console.log('getDriveViewDetails Error ::' + findErrorMessage(error));
      return;
    }
  };

  insuranceMessageInfoNotEmpty = info => {
    let oneArr = this.getDriveViewOffers(info);
    let twoArr = this.getDriveViewAds(info);
    return oneArr.length > 0 || twoArr.length > 0;
  };

  getDriveViewOffers = info => {
    return (info && Array.isArray(info.offers) && info.offers) || [];
  };

  getDriveViewAds = info => {
    return (info && info.marketPlaceAds && Array.isArray(info.marketPlaceAds.ads) && info.marketPlaceAds.ads) || [];
  };

  isReadyToDisplayDriveViewScore = () => {
    let baseIsThere = this.state.driveViewInfo && this.state.insuranceMessagesInfo;
    let snapshotIsThere = baseIsThere && this.state.driveViewInfo.snapShot;
    let insuranceIsThere =
      baseIsThere &&
      this.state.insuranceMessagesInfo &&
      !!this.insuranceMessageInfoNotEmpty(this.state.insuranceMessagesInfo);
    return snapshotIsThere || insuranceIsThere;
  };

  render () {
    console.log('DriveViewHome >> render >>');
    console.log(this.state.insuranceMessagesInfo);

    return this.state.dataLoading ? (
      <div class='driveview-scss-insulator'>
        <div>
          <div className='driveViewComponentHeading'>{this.translator.t('driveview.label.heading')}</div>
          {this.props.tripStatsEnrolled && (
            <span className='driveViewTripStatsDiv'>
              <a href='/#/mqbtrips'>
                <button type='button' className='driveViewTripStatsBtn'>
                  {this.translator.t('driveview.label.tripstats')}
                </button>
              </a>
            </span>
          )}
        </div>
        <div>
          <div className='driveViewComponentSubHeading'>{this.translator.t('driveview.label.sub-heading')}</div>
          {!!this.state.driveViewInfo.snapShot && (
            <div id='snapShot'>
              <Snapshot snapShot={this.state.driveViewInfo.snapShot} />
            </div>
          )}

          <div>
            {}
            {<GeicoMsgAvailable available={this.state.geicoMsgIsAvailable} />}
          </div>
          {this.state.insuranceOffersAvailable &&
          this.state.insuranceMessagesInfo &&
          !!this.insuranceMessageInfoNotEmpty(this.state.insuranceMessagesInfo) ? (
            <div id='insuranceMessage' className='driveComponentSectionPadding insuranceMessageWrapper'>
              <InsuranceMessages
                offers={this.getDriveViewOffers(this.state.insuranceMessagesInfo)}
                ads={this.getDriveViewAds(this.state.insuranceMessagesInfo)}
              />
            </div>
          ) : (
            this.state.insuranceOffersAvailable && (
              <div>
                <div className='driveViewComponentNoIM'>{this.translator.t('driveview.alerts.no-offers-text')}</div>
              </div>
            )
          )}

          <div id='aggregatedSnapshot' className='driveComponentSectionPadding'>
            <ConsolidateTripSummary
              consolidateTripSummary={this.state.driveViewInfo.consolidateTripSummary}
              handleViewType={this.handleViewType}
              handlePrevNext={this.handlePrevNext}
              daySelected={this.state.daySelected}
              weekSelected={this.state.weekSelected}
              monthSelected={this.state.monthSelected}
              previousSelected={this.state.previousSelected}
              nextSelected={this.state.nextSelected}
              trackDate={this.state.trackDate}
            />
          </div>

          <div id='tripsHistory' className='driveComponentSectionPadding'>
            <TripsHistory tripDetails={this.state.driveViewInfo.tripDetails} />
          </div>
        </div>
      </div>
    ) : (
      <div>
        <Loader />
      </div>
    );
  }
}

export default DriveViewHome;
